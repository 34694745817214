import { atom } from "recoil";

const initAppContext = {
    corporateName: "",
    corporateDisplay:""
}

export const UserContext = atom({
    key: "User Atom",
    default: initAppContext
})