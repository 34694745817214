import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import APPLICATION_CONSTANTS from "../../constants/constants";
import { fetchAuthSession } from "aws-amplify/auth";
import { AxiosBaseUrlConfig } from "../../pages/Config";

let axiosInstance = null;

export const createAxiosInstance = () => {
    axiosInstance = axios.create({
        baseURL: AxiosBaseUrlConfig().trim() + "/secure",
        headers: {
            "Accept-Language": APPLICATION_CONSTANTS.APPLICATION_LANGUAGES.at(0),
            "X-Client-Version": APPLICATION_CONSTANTS.REACT_APP_VERSION,
            "X-Channel-ID": APPLICATION_CONSTANTS.X_CHANNEL_ID,
        }
    });

    attachInterceptor(axiosInstance);
};



export async function fetchRefreshToken() {
    try {
        const { tokens } = await fetchAuthSession();
        let jwtToken = tokens.idToken.toString();
        if (tokens) {
            // jwtExpirationTime is in "seconds"
            const jwtExpirationTime = tokens.idToken.payload.exp;
            // Date.now() sends timestamp in ms
            if (jwtExpirationTime - APPLICATION_CONSTANTS.EXPIRY_TIME_OFFSET >= Math.ceil(Date.now()/1000)) {
                const { tokens } = await fetchAuthSession({ forceRefresh: true });
                jwtToken = tokens.idToken.toString();
            }
        }
        return jwtToken;
    } catch (err) {
        console.log("fetchAuthSession error", err);
    }
}
const attachInterceptor = (axiosInstance) => {
axiosInstance.interceptors.request.use(async function (config) {
    try {
        const jwtToken = await fetchRefreshToken();
        config.headers.Authorization = jwtToken;
        config.headers["X-Correlation-ID"] = `${uuidv4()}-crid`;
        return config;

    } catch (error) {
        console.error(`Error in axios interceptor - ${error}`);
    }
});
}

export const getAxiosInstance = () => axiosInstance
