import React, { lazy } from "react";
import FE_URL from "./constants/urls";
import Header from "./components/layout/Header";
import PrivateRoute from "./pages/ProtectedRoute";
import { Navigate } from "react-router-dom";

const ErrorPage = lazy(() => import('./pages/Error'));
const LandingPage = lazy(() => import('./pages/LandingPage'));
const Welcome = lazy(() => import('./pages/Home'));
const CaseCreation = lazy(() => import('./pages/case/CaseCreation'));
const CaseDetails = lazy(() => import('./pages/case/CaseDetails'));
const CaseListing = lazy(() => import('./pages/case/CaseListing'));
const Notification = lazy(() => import('./pages/Notification'));
const Reports = lazy(() => import('./pages/Reports/Reports'));

const routeConfig = [
    {
        path: "/",
        element: <Navigate to={`${FE_URL.LANDING_PAGE}`} replace />,
        errorElement: <ErrorPage />,
    },
    {
        path: FE_URL.LANDING_PAGE,
        element: <LandingPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: `/:sgh/${FE_URL.WELCOME}`,
        element: <PrivateRoute><Welcome /></PrivateRoute>,
        errorElement: <ErrorPage />,
    },

    {
        path: ":sgh",
        element: <Header />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: FE_URL.CASE,
                errorElement: <ErrorPage />,
                children: [
                    {
                        path: FE_URL.CASE_CREATION,
                        element: <PrivateRoute><CaseCreation /></PrivateRoute>,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: FE_URL.CASE_DETAILS,
                        element: <PrivateRoute><CaseDetails /></PrivateRoute>,
                        errorElement: <ErrorPage />,
                    },
                    {
                        path: FE_URL.CASE_LISTING,
                        element: <PrivateRoute><CaseListing /></PrivateRoute>,
                        errorElement: <ErrorPage />,
                    }
                ]
            },
            {
                path: FE_URL.NOTIFICATION,
                element: <PrivateRoute><Notification /></PrivateRoute>,
                errorElement: <ErrorPage />,
            },
            {
                path: FE_URL.REPORTS,
                element: <PrivateRoute><Reports /></PrivateRoute>,
                errorElement: <ErrorPage />,
            }
        ]
    },
];

export default routeConfig;