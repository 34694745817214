import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import palette from './pallete/palette';
import MuiCssBaseline from './components/MuiCssBaseline';
import typography from './typography';
import MuiChip from './components/MuiChip';
import MuiButton from './components/MuiButton';
import MuiAccordionSummary from "./components/MuiAccordionSummary";
import MuiSnackbarContent from "./components/MuiSnackbarContent";
import MuiSnackbar from "./components/MuiSnackbar";


const muiTheme = createTheme({
  palette,
  typography,
  components: {
    MuiCssBaseline,
    MuiChip,
    MuiButton,
    MuiAccordionSummary,
    MuiSnackbar,
    MuiSnackbarContent,
  }
});

const theme = responsiveFontSizes(muiTheme);

export default theme;
