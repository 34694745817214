import APPLICATION_CONSTANTS from "../constants/constants";
import { getS3Object } from "../services/s3";

/**
 * @param setApplicationContext - recoil setRecoilAtom
 * sets application context - prelogin values for application
 */

const getLanguagePack = (setApplicationContext, endpoint) => {
    // call language pack api to get the latest language pack
    getS3Object(endpoint)
      .then(({ data }) => {
        Promise.all([
          getS3Object(data.languagePackUrl),
        ]).then(([languagePack]) => {
          setApplicationContext(
            {
              language: APPLICATION_CONSTANTS.APPLICATION_LANGUAGES.at(0).toLowerCase(),
              languagePack: languagePack.data.content[APPLICATION_CONSTANTS.APPLICATION_LANGUAGES.at(0).toLowerCase()] ?? [],          
            }
          )
        })
          .catch(error => console.log(error));
      })
      .catch(error => console.log(error));
  }

  export default getLanguagePack;