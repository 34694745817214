import { AMBER, BLACK_WHITE, BLUE, GREEN, LIGHT_BLUE, LIGHT_GREEN, RED } from './colors';

const palette = {
    primary: {
        main: LIGHT_GREEN[400],
        contrastText: BLACK_WHITE[50],     
    },
    primaryIcon: {
        main: BLACK_WHITE[50],
    },
    secondary: {
        main: BLACK_WHITE[400],
        contrastText: BLACK_WHITE[900],
    },
    error: {
        main: RED[500]
    },
    info: {
        main: AMBER[100]
    },
    failure: {
        main: AMBER[600]
    },
    transparent: {
        main: "transparent",
        contrastText: BLACK_WHITE[600],
    },
    transparentIcon: {
        main: BLACK_WHITE[600],
    },
    cyan: {
        main: GREEN[700],
        contrastText: BLACK_WHITE[50],
    },
    cyanIcon: {
        main: BLACK_WHITE[50],
    },
    emerald: {
        50: LIGHT_GREEN[50],
        100: LIGHT_GREEN[100],
        200: LIGHT_GREEN[200],
        250: LIGHT_GREEN[250],
        300: LIGHT_GREEN[300],
        400: LIGHT_GREEN[400],
        500: LIGHT_GREEN[500],
        600: LIGHT_GREEN[600],
        700: LIGHT_GREEN[700],
        800: LIGHT_GREEN[800],
        900: LIGHT_GREEN[900]
    },
    neutral: {
        0: BLACK_WHITE[0],
        50: BLACK_WHITE[50],
        100: BLACK_WHITE[100],
        200: BLACK_WHITE[200],
        300: BLACK_WHITE[300],
        400: BLACK_WHITE[400],
        500: BLACK_WHITE[500],
        600: BLACK_WHITE[600],
        700: BLACK_WHITE[700],
        800: BLACK_WHITE[800],
        900: BLACK_WHITE[900],
        950: BLACK_WHITE[950]
    },
    lightBlue:{
        50: LIGHT_BLUE[50],
        100: LIGHT_BLUE[100],
        200: LIGHT_BLUE[200],
        300: LIGHT_BLUE[300],
        400: LIGHT_BLUE[400],
        500: LIGHT_BLUE[500],
        600: LIGHT_BLUE[600],
        700: LIGHT_BLUE[700],
        800: LIGHT_BLUE[800],
        900: LIGHT_BLUE[900]
    },
    blue:{
        50: BLUE[50],
        100: BLUE[100],
        200: BLUE[200],
        300: BLUE[300],
        400: BLUE[400],
        500: BLUE[500],
        600: BLUE[600],
        700: BLUE[700],
        800: BLUE[800],
        900: BLUE[900]
    },
    green: {
        50: GREEN[50],
        100: GREEN[100],
        200: GREEN[200],
        300: GREEN[300],
        400: GREEN[400],
        500: GREEN[500],
        600: GREEN[600],
        700: GREEN[700],
        800: GREEN[800],
        900: GREEN[900]
    },
    background:{
        green: "rgba(31, 132, 91, 0.50)"
    },
    gradient:{
        main: `linear-gradient(90deg, ${LIGHT_GREEN[400]} 0%, ${LIGHT_BLUE[400]} 112.4%)`
    },
    
}

export default palette; 