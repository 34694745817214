import React, { useEffect, useState } from 'react';
import { Navigate } from "react-router-dom";
import propTypes from "prop-types";
import FE_URL from '../constants/urls';
import { getCurrentUser } from 'aws-amplify/auth';

function PrivateRoute({ children }) {
    const [component, setComponent] = useState(<></>);

    useEffect(() => {
        getCurrentUser()
            .then(() => setComponent(children))
            .catch(() => setComponent(<Navigate to={FE_URL.LANDING_PAGE} replace />));
    }, [children]);
    return (component);
}

PrivateRoute.propTypes = {
    component: propTypes.array
};
export default PrivateRoute;