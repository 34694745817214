import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import AppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Outlet, useNavigate } from 'react-router-dom';
import handleLogOut from '../../utils/handleLogout';
import Divider from '@mui/material/Divider';
import FE_URL from '../../constants/urls';
import HIIPLogo from "../../assets/icons/HiipTransparentLogo.svg";
import SignOutIcon from "../../assets/icons/SignOut.svg";
import BellIcon from "../../assets/icons/Bell.svg";
import CustomModal from './CustomModal';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ApplicationContext } from '../../recoil/atoms/ApplicationContext';
import { UserContext } from '../../recoil/atoms/UserContext';
import apiCall from '../../services';
import ENDPOINT from '../../constants/endpoints';
import APPLICATION_CONSTANTS from '../../constants/constants';

import styled from "@emotion/styled";
import { getCurrentUser, fetchUserAttributes } from '@aws-amplify/auth';

const CustomAppbar = styled(AppBar)(({ theme, background }) => ({
    background: background === "transparent" ? background : theme.palette.gradient.main,
    boxShadow: "none"
}));

export default function Header({ background }) {

    const [username, setUsername] = useState("");
    const [isSignoutModal, setIsSignoutModal] = useState(false);
    const navigate = useNavigate();
    const { languagePack } = useRecoilValue(ApplicationContext);
    const setUserAtom = useSetRecoilState(UserContext);
    const [userCoporateDisplay, setUserCoporateDisplay] = useState(""); // for Display
    const [userCoporateName, setUserCoporateName] = useState("");// for navigation
    const [isNotification, setIsNotification] = useState(false);

    const openSignoutModal = () => setIsSignoutModal(prev => !prev);

    useEffect(() => {
        getCurrentUser()
            .then(({ username }) => {
                setUsername(username);
            })
            .catch(() => {
                setUsername("");
            });

        fetchUserAttributes().then(attributes => {
            const corporateName = languagePack?.[`${attributes?.['custom:corp_sub_identity']}_corporate`];
            const corporateDisplay = languagePack?.[`${attributes?.['custom:corp_sub_identity']}_corporateDisplayName`];
            setUserAtom(prev => {
                return {
                    ...prev,
                    corporateName,
                    corporateDisplay
                };
            });

            setUserCoporateName(languagePack?.[`${attributes?.['custom:corp_sub_identity']}_corporate`]);
            setUserCoporateDisplay(languagePack?.[`${attributes?.['custom:corp_sub_identity']}_corporateDisplayName`]);

        }).catch(() => {
            setUserCoporateName("");
            setUserCoporateDisplay("");
        });

    }, [languagePack, userCoporateName]);

    useEffect(() => {
        const callNotificationApi = setInterval(() => {
            apiCall(ENDPOINT.INBOX_MESSAGE_METADATA_INQUIRY)
                .then(({ data }) => setIsNotification(data.hasUnread ?? false))
                .catch(() => setIsNotification(false));
        }, APPLICATION_CONSTANTS.NOTIFICATION_INTERVAL);

        return () => clearInterval(callNotificationApi);

    }, []);


    return (
        <Box sx={{ flexGrow: 1 }} tabIndex={0}>
            <CustomAppbar background={background} position="static" component="nav">
                <Toolbar >
                    {background !== "transparent" &&
                        <Box
                            justifyContent="center"
                            mr={2}
                            sx={{ cursor: 'pointer' }}
                        >
                            <img
                                src={HIIPLogo}
                                alt="logo"
                                height={40}
                                width={46}
                                onClick={() => navigate(`/${userCoporateName?.toLowerCase()}/${FE_URL.WELCOME}`)} aria-hidden />
                        </Box>
                    }
                    <Divider orientation='vertical' />
                    <Box
                        flexGrow={1}
                        mx={2}>
                        {background !== "transparent" &&
                            <Typography color="neutral.50" variant='h5'>
                                {userCoporateDisplay}
                            </Typography>
                        }
                    </Box>
                    <Stack
                        direction="row"
                        textAlign="end"
                        alignItems="center"
                        gap={3}>
                        < Typography variant="body1" fontWeight={700}>
                            {username}
                        </Typography>
                        {
                            isNotification ?
                                <Badge color="error" overlap="circular" badgeContent=" " variant="dot">
                                    <IconButton
                                        name="notification"
                                        size="small"
                                        aria-label="Notification"
                                        onClick={() => navigate(`/${userCoporateName}/${FE_URL.NOTIFICATION}`)}
                                    >
                                        <img src={BellIcon} alt="Notification icon" />
                                    </IconButton>
                                </Badge>
                                :
                                <IconButton
                                    name="notification"
                                    size="small"
                                    aria-label="Notification"
                                    onClick={() => navigate(`/${userCoporateName}/${FE_URL.NOTIFICATION}`)}
                                >
                                    <img src={BellIcon} alt="Notification icon" />
                                </IconButton>
                        }
                        <IconButton
                            name="Signout"
                            size="small"
                            aria-label="Log out"
                            onClick={openSignoutModal} >
                            <img src={SignOutIcon} alt="Signout" />
                        </IconButton>
                    </Stack>
                </Toolbar>
            </CustomAppbar>
            {
                isSignoutModal &&
                <CustomModal props={
                    {
                        title: languagePack?.["logout.header.signout_modal.title.label"],
                        titleColor: "primary",
                        description: languagePack?.["logout.header.signout_modal.description.label"],
                        handleClose: () => setIsSignoutModal(prev => !prev),
                        open: isSignoutModal,
                        btns: [
                            {
                                key:1,
                                color: "primary",
                                variant: "contained",
                                onClick: () => { handleLogOut(navigate); },
                                label: languagePack?.["logout.header.signout_modal.confirm.button"]
                            },
                            {
                                key:2,
                                color: "primary",
                                variant: "outlined",
                                onClick: () => { setIsSignoutModal(prev => !prev); },
                                label: languagePack?.["logout.header.signout_modal.cancel.button"]
                            },
                        ]
                    }}
                />
            }
            <div id="detail">
                <Outlet />
            </div>
        </Box >
    );
}

Header.propTypes = {
    background: PropTypes.string
};