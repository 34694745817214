const APPLICATION_CONSTANTS = {
    APPLICATION_LANGUAGES: ["en-SG"],
    HEADER_HEIGHT: 64,
    APPLICATION_NAME: "HIIP-HOSPITAL",
    X_CHANNEL_ID: "HOSPITAL_SERVICE_WEB",
    REACT_APP_VERSION: "v0.1.0",
    EXPIRY_TIME_OFFSET: 4 * 60 * 1000, // 4 mins
    CASE_STATUS_MAPPING: {
        SUBMIT_TO_DO: "SUBMIT_TO_DO",
        SUBMITTED_RANK_TO_DO: "SUBMITTED_RANK_TO_DO",
        UPDATED_RANK_TO_DO: "UPDATED_RANK_TO_DO",
        AUTH_TO_DO: "AUTH_TO_DO",
        AUTH_IN_PROGRESS: "AUTH_IN_PROGRESS",
        AUTH_DONE: "AUTH_DONE",
        CASE_VOIDED: "CASE_VOIDED"
    },
    DISCHARGE_STATUS_MAPPING: {
        NOT_ADMITTED: "NOT_ADMITTED",
        ADMITTED: "ADMITTED",
        DISCHARGED: "DISCHARGED",
    },
    NA: "-",
    NOTIFICATION_INTERVAL: 30000,
    OTHERS_REF_ID: '999999',
    OUTCOME_BLOCKED: 'OUTCOME_BLOCKED',
    CASE_LISTING_REPORT_TYPE: 'RP01',
    COMPLETED: 'Completed',
    ALPHANUMERIC: 'ALPHANUMERIC',
    ROW_ACTIONS: {
        ADD: "add",
        DELETE: "delete"
    }
};

export default APPLICATION_CONSTANTS;