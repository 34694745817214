import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { sharedInMemoryStorage } from '@aws-amplify/core';

export function CognitoConfig() {
    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
                userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
                userPoolEndpoint: process.env.REACT_APP_COGNITO_URL
            }
        }
    });

    cognitoUserPoolsTokenProvider.setKeyValueStorage(sharedInMemoryStorage);

    // get the current config object
    Amplify.getConfig();
}


// csp config
export function CSPConfig() {
    const csp = " script-src 'self' 'nonce-MY_NONCE'; " +
        " img-src 'self'; " +
        " font-src 'self'; " +
        " connect-src 'self' " + process.env.REACT_APP_API_NOAUTH + " " +  process.env.REACT_APP_COGNITO_URL + " https://hcn-001-sit-hiip-bucket.s3.ap-southeast-1.amazonaws.com " + " ; " +
        " style-src 'self' 'nonce-MY_NONCE'; " +
        " media-src 'self'; " +
        " object-src 'none'; ";

    return csp;
}

// axios base url

export function AxiosBaseUrlConfig() {
    return process.env.REACT_APP_API_NOAUTH;
}

export function GetPreloginBucketEndpoint() {
    return "https://hcn-001-sit-hiip-bucket.s3.ap-southeast-1.amazonaws.com"
}