import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { sharedInMemoryStorage } from '@aws-amplify/core';
import apiCall from "../services";
import ENDPOINT from "../constants/endpoints";
import FE_URL from "../constants/urls";

export default async function handleLogOut(navigate) {
    const {idToken, refreshToken, accessToken} = await cognitoUserPoolsTokenProvider.authTokenStore.loadTokens()
    // CALL LOGOUT API AND CLEAR SHARED MEMORY STORAGE
    apiCall(ENDPOINT.LOGOUT,{
        idToken: idToken.toString(),
        refreshToken: refreshToken.toString(),
        accessToken: accessToken.toString()
    }).then(() => {
        sharedInMemoryStorage.clear();
        navigate(FE_URL.LANDING_PAGE, {replace: true})
    })

}
