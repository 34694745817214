const ENDPOINT = {
    PRELOGIN_BUCKET: "/prelogin/config/prelogin.json",

    CASE_CREATION: "/corp-hospital/v1/case/creation",
    CASE_LIST_INQUIRY: "/corp-hospital/v1/case/list/inquiry",
    CASE_DETAIL_VOID: "/corp-hospital/v1/case/detail/void",
    CASE_INQUIRY_DETAIL: "/corp-hospital/v1/case/detail/inquiry",
    CASE_SUBMISSION: "/corp-hospital/v1/case/submission",
    CASE_UPDATE: "/corp-hospital/v1/case/update",
    CASE_UPLOAD_PDF:"/corp-hospital/v1/upload",
    CASE_PROCESS_PDF:"/corp-hospital/v1/process",

    INBOX_MESSAGE_INQUIRY: "/corp-hospital/v1/inbox/message/inquiry",
    INBOX_MESSAGE_METADATA_INQUIRY:"/corp-hospital/v1/inbox/message/metadata/inquiry",
    INBOX_MESSAGE_STATUS_UPDATE: "/corp-hospital/v1/inbox/message/status/update",

    DIAGNOSIS_ICD_SEARCh: "/corp-hospital/v1/case/diagnosis-icd/search",

    REPORT_TYPE_LIST_INQUIRY: "/corp-hospital/v1/reports/report-type/list/inquiry",
    GENERATE_REPORT_SUBMISSION: "/corp-hospital/v1/reports/generate-report/submit",
    REPORT_SUMMARY: "/corp-hospital/v1/reports/report-summary/list/inquiry",
    REPORT_DOWNLOAD: "/corp-hospital/v1/reports/download-report",

    LOGOUT: "/corp-hospital/v1/auth/signout"
}

export default ENDPOINT