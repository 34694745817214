import React from 'react';
import propTypes from "prop-types";
import { useRouteError } from "react-router-dom";
import { useRecoilValue } from 'recoil';
import styled from "@emotion/styled";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";

import { ApplicationContext } from '../recoil/atoms/ApplicationContext';

import ErrorIcon from '../assets/icons/Error.svg';

const BlueGrid = styled(Grid)(({ theme }) => ({
    background: theme.palette.lightBlue[50],
    minHeight: "100vh"
}));

/**
 * 
 * @param {error} param0 - comes from DOM, Lifecycle errors from react-boundary-error
 * @returns error page
 */
export default function ErrorPage({ error }) {
    const routeError = useRouteError(); // coming from route errors
    let errorCalculated = error || routeError;
    const languagePack = useRecoilValue(ApplicationContext);
    const refresh = () => {
        window.location.reload(false);
    };

    if (!errorCalculated) {
        errorCalculated = {
            title: languagePack?.["error.page_load_failure.title.label"],
            message: languagePack?.["error.page_load_failure.description.label"],
            ctaText: languagePack?.["error.page_load_failure.refresh.button"],
            cta: refresh
        };
    }

    if (errorCalculated.status === 404) {
        errorCalculated = {
            title: errorCalculated.statusText,
            message: errorCalculated.data,
            cta: errorCalculated.cta
        };
    }
    return (
        <BlueGrid
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
            textAlign="center">
            <Grid item xs={4}>
                <img src={ErrorIcon} alt=":(" />
                <Typography variant="h6" color="lightBlue.400" mb={1}>
                    {errorCalculated?.title?.toString()?.toUpperCase()}
                </Typography>
                <Typography variant="p">
                    {errorCalculated?.message}
                </Typography>
                {errorCalculated.ctaText &&
                    <Button
                        variant="contained"
                        onClick={errorCalculated.cta}
                        fullWidth
                        sx={{ marginY: 2}}
                    >
                        {errorCalculated?.ctaText}
                    </Button>
                }
            </Grid>
        </BlueGrid>

    );
}

ErrorPage.propTypes = {
    error: propTypes.object
};
