"use client";
import './App.css';
import React, { Suspense, useEffect } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import { createAxiosInstance } from './utils/axios/axios';
import theme from './theme';
import Error from './pages/Error';
import routeConfig from './Routes';
import { ApplicationContext } from './recoil/atoms/ApplicationContext';
import getLanguagePack from './utils/getPreloginBucketDetails';
import Loader from './components/layout/Loader';
import { Helmet } from 'react-helmet-async';
import { CognitoConfig, CSPConfig, GetPreloginBucketEndpoint } from './pages/Config';
import ENDPOINT from './constants/endpoints';

// create Emotion cache with nonce
const createEmotionCache = (nonce) => createCache({
  key: 'mui',
  nonce
});

function App() {
  const router = createBrowserRouter(routeConfig);
  const setApplicationContext = useSetRecoilState(ApplicationContext);
  const nonce ="MY_NONCE";

  CognitoConfig();

  useEffect(() => {
    getLanguagePack(setApplicationContext, `${GetPreloginBucketEndpoint().trim()}${ENDPOINT.PRELOGIN_BUCKET}`);
    createAxiosInstance();
  }, []);

  const cache = createEmotionCache(nonce);

  return (
    <>
      <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content={CSPConfig()}
        ></meta>
      </Helmet>
      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ErrorBoundary FallbackComponent={Error}>
            <Suspense fallback={<Loader />}>
              <RouterProvider router={router} />
            </Suspense>
          </ErrorBoundary>
        </ThemeProvider>
      </CacheProvider>
    </>

  );
}

export default App;
