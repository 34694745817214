import { getAxiosInstance } from "../utils/axios/axios";

export default function apiCall(url, requestBody, headers) {
    return getAxiosInstance().post(
        url,
        requestBody,
        {
            headers
        }
    )
        .then(resp => resp)
        .catch(error => {
            if (error.response.status === 403) {
                window.location.href = "/"
            }else{
                throw error;
            }
        });
}