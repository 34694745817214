const FE_URL = {
    LANDING_PAGE: "/login",
    WELCOME: "home",
    CASE: "case",
    CASE_CREATION: "create",
    CASE_DETAILS: "details",
    CASE_LISTING: "listing",
    NOTIFICATION: "notification",
    REPORTS: "reports"
};
export default FE_URL;
